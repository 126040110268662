<template>
  <div>
    <div v-if="project">
      <v-row class="pa-0 ma-0 mx-2 mt-2">
        <v-col cols="12" md="8"
          ><v-card class="border-radius-lg">
            <v-toolbar density="compact" elevation="0">
              <v-btn
                class="bg-light mr-2"
                elevation="0"
                @click="$router.go(-1)"
              >
                <v-icon size="15" class="text-secondary"
                  >fas fa-chevron-left</v-icon
                >
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                class="bg-light mr-2"
                elevation="0"
                :href="`tel: ${project.customer.phone}`"
                v-if="
                  project.customer &&
                  project.customer.phone &&
                  project.customer.phone.length > 6
                "
              >
                <v-icon size="15" class="text-secondary">fas fa-phone</v-icon>
              </v-btn>

              <v-btn
                class="bg-light text-secondary"
                elevation="0"
                @click="openChat(project)"
              >
                <v-icon size="15">fas fa-comment</v-icon>
                <div class="ml-2">Chatten</div>
              </v-btn>
              <v-btn
                class="bg-light text-secondary ml-2"
                elevation="0"
                @click="
                  $store.commit('customTab', null);
                  $store.commit('selectedProject', project);
                  openEditProjectModal();
                "
              >
                <v-icon size="15" class="">fas fa-edit</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-row class="justify-center pt-3 pb-5 px-9">
              <div class="text-center">
                <v-avatar
                  size="150"
                  class="ma-3 text-uppercase text-dark bg-gradient-warning"
                >
                  <v-img
                    :src="getImage(project.imagePath)"
                    :alt="project.title"
                    v-on:error="onImgError"
                  />
                </v-avatar>
                <h5 class="text-h5 mt-2 font-weight-bold text-typo">
                  {{ project.title }}
                </h5>
                <div
                  class="mb-1 font-weight-normal text-md"
                  v-if="project.serviceProvider"
                >
                  {{ project.serviceProvider.name }}
                  {{ project.serviceProvider.legalForm }}
                </div>
              </div>
            </v-row>
            <div class="pa-2 px-5 pt-3">
              <div
                class="bg-light mb-5 font-weight-bold border-radius-lg pa-3"
                style="cursor: pointer"
                @click="
                  $store.commit('selectedProject', project);
                  documentsModal = true;
                "
              >
                <v-row class="align-center pa-0 ma-0 justify-space-between">
                  <div>Dokumente</div>
                  <div class="text-secondary">
                    <v-row class="align-center">
                      <div class="mr-2 pt-1 font-weight-normal">
                        {{ project.documents.length }}
                      </div>
                      <div class="mr-3">
                        <v-icon size="15">fas fa-chevron-right</v-icon>
                      </div>
                    </v-row>
                  </div>
                </v-row>
              </div>
              <div
                class="bg-light mb-5 font-weight-bold border-radius-lg pa-3"
                style="cursor: pointer"
                @click="
                  $store.commit('customTab', 'manager');
                  $store.commit('selectedProject', project);
                  project.manager
                    ? openUserInfoModal(project.manager)
                    : openEditProjectModal();
                "
              >
                <v-row class="align-center pa-0 ma-0 justify-space-between">
                  <div>Projektmanager</div>
                  <div class="text-secondary">
                    <v-row class="align-center">
                      <div
                        class="mr-2 pt-1 text-secondary font-weight-normal"
                        v-if="project.manager"
                      >
                        {{ project.manager.firstname }}
                        {{ project.manager.lastname }}
                      </div>
                      <div class="mr-3">
                        <v-icon size="15">fas fa-chevron-right</v-icon>
                      </div>
                    </v-row>
                  </div>
                </v-row>
              </div>
              <div
                class="bg-light mb-5 font-weight-bold border-radius-lg pa-3"
                style="cursor: pointer"
                @click="
                  $store.commit('customTab', 'customer');
                  $store.commit('selectedProject', project);
                  project.customer
                    ? selectCustomer(project.customer)
                    : openEditProjectModal();
                "
              >
                <v-row class="align-center pa-0 ma-0 justify-space-between">
                  <div>Kunde</div>

                  <div class="text-secondary">
                    <v-row class="align-center">
                      <div
                        v-if="project.customer"
                        class="mr-2 pt-1 text-secondary font-weight-normal"
                      >
                        {{ project.customer.name }}
                        {{ project.customer.legalForm }}
                      </div>
                      <v-col>
                        <div class="mr-3">
                          <v-icon size="15">fas fa-chevron-right</v-icon>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-row>
              </div>
            </div>
          </v-card>
          <v-card class="border-radius-lg mt-10">
            <v-row class="justify-space-between pt-3 pb-5 px-9">
              <div>
                <h5 class="text-h5 my-2 font-weight-bold text-typo">
                  Projektphasen
                </h5>
              </div>
            </v-row>
            <div class="pa-2 px-5 pt-3">
              <div
                class="bg-light mb-3 font-weight-bold border-radius-lg pa-3"
                v-for="phase in project.phases"
                :key="phase.id"
                style="cursor: pointer"
                @click="changePhaseState(phase)"
              >
                <v-row class="align-center pa-0 ma-0">
                  <v-row class="align-center"
                    ><v-avatar
                      size="30"
                      class="ma-3 text-uppercase text-dark"
                      :color="phase.done ? '#edd680' : 'grey'"
                    >
                      <v-icon
                        size="8"
                        :class="phase.done ? 'text-dark' : 'text-white'"
                        class="pa-2"
                        >fas fa-check</v-icon
                      >
                    </v-avatar>
                    <div>
                      {{ phase.title }}
                    </div></v-row
                  >
                </v-row>
              </div>
            </div>
          </v-card></v-col
        >
        <v-col cols="12" md="4">
          <Tasks :project="project" :title="true" />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div v-if="project">
        <v-row class="pa-0 ma-0 mx-2 my-10">
          <v-col cols="12">ERROR</v-col>
        </v-row>
      </div>
    </div>

    <v-dialog scrollable v-model="editProjectModal" max-width="500px">
      <edit-project-modal />
    </v-dialog>
    <v-dialog v-model="documentsModal" max-width="500px" persistent>
      <DocumentsVue @close="documentsModal = false" />
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Tasks from "@/components/Cards/Tasks.vue";
import DocumentsVue from "@/components/Modals/Documents.vue";

export default {
  name: "ProjektDetails",
  components: {
    draggable,
    Tasks,
    DocumentsVue,
  },
  data: function () {
    return {
      documentsModal: false,
      newTaskActive: true,
    };
  },
  computed: {
    project() {
      if (this.projects) {
        if (this.projects.data) {
          try {
            return this.projects.data.find(
              (project) => project.id == this.$route.params.id
            );
          } catch (error) {
            return null;
          }
        }
      }
      return null;
    },
  },

  mounted() {
    if (!this.project) {
      this.$router.push("/projects");
    }
  },
  methods: {
    changePhaseState(phase) {
      this.$swal({
        title: `Willst du ${phase.title} als ${
          !phase.done ? "abgeschlossen" : "offen"
        } markieren?`,
        // text: "Du kannst dies nicht rückgängig machen!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: `Ja, bitte!`,
        cancelButtonText: "Nein, abbrechen!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$swal.fire(
            `Phase ${phase.title} wurde ${
              !phase.done ? "abgeschlossen" : "als offen markiert"
            }`,
            "",
            "success"
          );

          this.project.lastUpdatedMessage = `Phase ${phase.title} wurde ${
            !phase.done ? "abgeschlossen" : "als offen markiert"
          }`;

          phase.done = phase.done == "1" ? false : true;
          this.$store.dispatch("updateProject", this.project);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          // this.$swal.fire("Löschen abgebrochen", "", "error");
        }
      });
    },
  },
};
</script>

<style>
</style>